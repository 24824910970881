import { ThrowStmt } from '@angular/compiler';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlbumService } from '../service/album.service';

@Component({
  selector: 'app-album-image',
  templateUrl: './album-image.component.html',
  styleUrls: ['./album-image.component.scss']
})
export class AlbumImageComponent implements OnInit {
  albumDisplayId: string;
  album: any = {};
  constructor(private router: Router, private route: ActivatedRoute,
    private albumService: AlbumService) {
    route.queryParams.subscribe(param => {
      this.reloadCurrentRoute();
    });

  }
  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.route.queryParams.forEach(params => {
      this.albumDisplayId = params["id_"];
      if (!this.albumDisplayId || this.albumDisplayId.length < 2) {
        this.router.navigate(['/photo-album']);
      } else {
        this.getAlbumByDisplayUrl();
      }
    });
  }

  reloadCurrentRoute() {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }
  getAlbumByDisplayUrl() {
    this.albumService.getAlbumByDisplayUrl(this.albumDisplayId).then(response => {
      if (response.status) {
        this.album = response.data;
      }
    }
    );
  }
}
