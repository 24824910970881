import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-album-image-view',
  templateUrl: './album-image-view.component.html',
  styleUrls: ['./album-image-view.component.scss']
})
export class AlbumImageViewComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
