import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from "./home/home.component";
import { PlanComponent } from "./plan/plan.component";
import { DepartmentComponent } from "./department/department.component";
import { DetailsPageComponent } from "./details-page/details-page.component";
import { AlbumImageComponent } from "./album-image/album-image.component";
import { AlbumComponent } from "./album/album.component";
import { PrivacyPolicyComponent } from "./privacy-policy/privacy-policy.component";

const routes: Routes = [
  {
    path: "",
    redirectTo: "home",
    pathMatch: "full",
  },
  {
    path: "home",
    component: HomeComponent,
  },
  {
    path: "department",
    component: DepartmentComponent,
  },
  {
    path: "plan",
    component: PlanComponent,
  },
  {
    path: "details",
    component: DetailsPageComponent,
  },
  {
    path: "photo-album",
    component: AlbumComponent,
  },
  {
    path: "photo-gallery",
    component: AlbumImageComponent,
  },
  {
    path: "privacy-policy",
    component: PrivacyPolicyComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
