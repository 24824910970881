import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { ApiService } from "./api.service";
import { ComponentInteractionService } from "./component-interaction.service";
import { ApiStatus } from "../model/common";
import { UrlConstants } from "../constants/url-constants";

@Injectable({
  providedIn: "root",
})
export class DepartmentService extends ApiService {
  constructor(
    private http: HttpClient,
    private cci: ComponentInteractionService
  ) {
    super(http, cci);
  }

  getDepartmentsByType(departmentTypeId: number): Promise<ApiStatus> {
    return this.doGet(
      UrlConstants.GET_DEPARTMENTS_BY_TYPE__GET +
        `?departmentTypeId=${departmentTypeId}`,
      false
    );
  }
  getDepartmentsByDisplayUrl(departmentDisplayUrl: string): Promise<ApiStatus> {
    return this.doGet(
      UrlConstants.GET_DEPARTMENTS_BY_DISPLAY_URL__GET +
        `?departmentDisplayUrl=${departmentDisplayUrl}`,
      false
    );
  }
  getPlansInDepartmentsByDisplayUrl(
    departmentDisplayUrl: string
  ): Promise<ApiStatus> {
    return this.doGet(
      UrlConstants.GET_PLAN_BY_DEPARTMENT_DISPLAY_URL__GET +
        `?departmentDisplayUrl=${departmentDisplayUrl}`,
      false
    );
  }
  getPlanByDisplayUrl(planDisplayUrl: string): Promise<ApiStatus> {
    return this.doGet(
      UrlConstants.GET_PLAN_BY_PLAN_DISPLAY_URL__GET +
        `?planDisplayUrl=${planDisplayUrl}`,
      false
    );
  }
  getPlanMarque(): Promise<ApiStatus> {
    return this.doGet(UrlConstants.GET_PLAN_BY_PLAN_MARQUEE__GET, false);
  }
  getLastAddedPlans(): Promise<ApiStatus> {
    return this.doGet(UrlConstants.GET_LAST_ADDED_PLANS, false);
  }
  v;
}
