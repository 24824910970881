<app-title-section></app-title-section>
<!-- <app-marquee-section></app-marquee-section> -->
<!-- ################################################################################################ -->
<!-- ################################################################################################ -->
<!-- ################################################################################################ -->
<div class="wrapper bgded overlay background-image-class container-new"
  style="background-image:url('./assets/images/backgrounds/afc.jpg');">

  <app-marquee-section></app-marquee-section>
  <div id="pageintro" class="hoc clear">
    <!-- ################################################################################################ -->

    <article>
      <p></p>
      <h3 class="heading">അതിരൂപത ഫെസിലിറ്റേഷൻ സെന്റർ</h3>
      <p class="details-p">സാമൂഹ്യ ക്ഷേമ പദ്ധതികൾ, തൊഴിൽ സാധ്യതകൾ<br /> അപേക്ഷകൾ നൽകാനും നേടിയെടുക്കാനും ഒരു കൈത്താങ്ങ്
      </p>
      <footer><a class="btn" (click)="navigateToDetails()">കൂടുതൽ വിവരങ്ങൾ</a></footer>
    </article>
    <!-- ################################################################################################ -->
  </div>
</div>
<!-- ################################################################################################ -->
<!-- ################################################################################################ -->
<!-- ################################################################################################ -->
<!-- <div class="wrapper row3">
  <main class="hoc container clear">
    <div class="sectiontitle">
      <h6 class="heading">വിവിധ പദ്ധതികൾ</h6>
    </div>
    <ul class="nospace group overview">
      <li *ngFor="let department of departmentList" class="one_third cursor-pointer "
        (click)="navigatetoDepartment(department)">
        <figure><a><img class="department-image"
              [src]="'/afc-restful/department/image?departmentDisplayUrl='+department.displayUrlId" alt=""></a>
          <figcaption>
            <h6 class="heading">{{department.departmentName}}</h6>
          </figcaption>
        </figure>
      </li>
    </ul>
    <div class="clear"></div>
  </main>
</div> -->
<div class="wrapper row2 container-new">
  <section id="latest" class="hoc container clear">
    <!-- ################################################################################################ -->
    <!-- <div *ngIf="department.departmentTypeId!=6" class="sectiontitle">
      <h6 class="heading noselect">{{department.departmentName}}</h6>
      <p class="noselect" [innerHTML]="department.description"></p>

    </div> -->
    <div class="sectiontitle">
      <h6 class="heading">വിവിധ പദ്ധതികൾ</h6>
    </div>
    <ul class="nospace group">
      <li *ngFor="let plan of planList; let i = index" style="margin-bottom: 20px;" class="one_third"
        [class.first]="i%3 == 0" [class.not-first-row]="i>2">
        <article class="plan-li">
          <figure>
            <a href="javascript:void(0)" (click)="navigatetoPlan(plan)"><img
                [src]="'/afc-restful/plan/image?planDisplayUrl='+plan.displayUrlId" alt=""></a>
          </figure>
          <div style="min-height: 200px;" class="excerpt">
            <h6 style="min-height: 81px;" class="heading heading-text noselect">{{plan.planName}}</h6>
            <footer style="position: relative;
            bottom: 10px;">
              <a class="button footer-class" href="javascript:void(0)" (click)="navigatetoPlan(plan)">Click here
                for more details
              </a>
            </footer>
          </div>
        </article>
      </li>
    </ul>
    <!-- ################################################################################################ -->
  </section>
</div>
<!-- ################################################################################################ -->
<!-- ################################################################################################ -->
<!-- ################################################################################################ -->
<div class="wrapper gradient">
  <section id="testimonials" class="hoc container clear pator-members-section">
    <!-- ################################################################################################ -->
    <div class="sectiontitle">
      <h6 class="heading">എ ഫ് സി ഭാരവാഹികൾ</h6>
      <!-- <p>In egestas vitae turpis sed velit urna sollicitudin ut euismod</p>  -->
    </div>
    <article class="one_half first"><img class="savors-image" src="./assets/images/mar_andrews_thazhath.jpg" alt="">
      <h6 class="heading">മാർ. ആൻഡ്രൂസ് താഴത്ത്</h6>
      <em>രക്ഷാധികാരി</em>
    </article>
    <article class="one_half"><img class="savors-image" src="./assets/images/mar_tony_neelamkavil.jpg" alt="">
      <h6 class="heading">മാർ. ടോണി നീലാകാവിൽ</h6>
      <em>ഉപ രക്ഷാധികാരി</em>
    </article>
    <!-- ################################################################################################ -->
  </section>
  <section class="hoc container clear commitee-members-section">
    <!-- ################################################################################################ -->
    <!-- <div class="sectiontitle"> -->
    <!-- <h6 class="heading">എ ഫ് സി ഭാരവാഹികൾ </h6> -->
    <!-- <p>Vestibulum nisi ut lectus proin lectus ante fermentum sed</p> -->
    <!-- </div> -->
    <div class="group center">
      <article class="one_third first"><a class="ringcon btmspace-50" href="#"><img class="savors-image image-round"
            src="./assets/images/fr_denny.jpg" alt=""></a>
        <h6 class="heading commitee-members-heading">ഫാ. ഡെന്നി താണിക്കൽ </h6>
        <p class="commitee-members-p">ഡയറക്ടർ</p>
        <p class="commitee-members-phone">9605043327</p>
      </article>
      <article class="one_third"><a class="ringcon btmspace-50" href="#"><img class="savors-image image-round"
            src="./assets/images/jomy_johnson.jpg" alt=""></a>
        <h6 class="heading commitee-members-heading">ശ്രീ. ജോമി ജോൺസൺ</h6>
        <p class="commitee-members-p">കൺവീനർ</p>
        <p class="commitee-members-phone">9447769815</p>
      </article>
      <article class="one_third"><a class="ringcon btmspace-50" href="#"><img class="savors-image image-round"
            src="./assets/images/james_aazchangadan.jpg" alt=""></a>
        <h6 class="heading commitee-members-heading">ശ്രീ. ജെയിംസ് ആഴ്‌ചങ്ങാടൻ</h6>
        <p class="commitee-members-p">ജോ. കൺവീനർ</p>
        <p class="commitee-members-phone">9846142576</p>

      </article>
    </div>
    <!-- ################################################################################################ -->
  </section>

  <section class="hoc container clear commitee-members-section">
    <div class="group center">
      <article class="one_half first"><a class="ringcon btmspace-50" href="#"><img class="savors-image image-round"
            src="./assets/images/dr_mary_regeena.jpg" alt=""></a>
        <h6 class="heading commitee-members-heading">ഡോ. മേരി റെജീന </h6>
        <p class="commitee-members-p">അഡ്വൈസറി ബോർഡ് മെമ്പർ</p>
        <p class="commitee-members-phone">9446130239</p>

      </article>
      <article class="one_half"><a class="ringcon btmspace-50" href="#"><img class="savors-image image-round"
            src="./assets/images/rony_agastin.jpg" alt=""></a>
        <h6 class="heading commitee-members-heading">ശ്രീ. റോണി അഗസ്റ്റ്യൻ</h6>
        <p class="commitee-members-p">അഡ്വൈസറി ബോർഡ് മെമ്പർ</p>
        <p class="commitee-members-phone">9946221499</p>
      </article>

    </div>
    <!-- ################################################################################################ -->
  </section>

</div>
<!-- ################################################################################################ -->
<!-- ################################################################################################ -->
<!-- ################################################################################################ -->
<!-- ################################################################################################ -->
<div class="wrapper gradient contact-us-div">
  <section id="cta" class="hoc container clear">
    <!-- ################################################################################################ -->
    <div class="sectiontitle">
      <h6 class="heading">കൂടുതൽ വിവരങ്ങൾക്ക്</h6>
      <!-- <p>in urna proin luctus ligula eget mauris quisque congue malesuada</p> -->
    </div>
    <ul class="nospace clear">
      <li class="one_third first">
        <div class="block clear contact-details-div"><a href="tel:+917902713325"><i class="fas fa-phone"></i></a>
          <span><strong>വിളിക്കുക:</strong> +91
            7902713325<br /> +91 7909293325</span>
        </div>
      </li>
      <li class="one_third">
        <div class="block clear contact-details-div"><a href="mailto:info@afctcr.com"> <i
              class="fas fa-envelope"></i></a> <span><strong>ഇമെയിൽ
              അയക്കുക:</strong>
            info@afctcr.com<br /> </span> </div>
      </li>
      <li class="one_third">
        <div class="block clear contact-details-div"> <a
            href="https://maps.google.com/maps?ll=10.526416,76.227681&z=16&t=m&hl=en-US&gl=US&mapclient=embed&daddr=Family%20Apostolate%20Centre%20Kizhakkumpattukara%20Rd%20Kizhakkumpattukara%20Thrissur%2C%20Kerala%20680005@10.526416,76.2276809"
            target="_blank"> <i class="fas fa-map-marker-alt"></i></a>
          <span><strong>എത്തിച്ചേരുക:</strong>
            <span class="address-text"> ഫാമിലി അപോസ്റ്റലേറ്റ് സെന്റർ<br />കിഴക്കുംപാട്ടുകര, തൃശൂർ, കേരളം</span>

          </span>
        </div>
      </li>
    </ul>
    <!-- ################################################################################################ -->
  </section>
</div>
<!-- ################################################################################################ -->
<!-- ################################################################################################ -->
<!-- ################################################################################################ -->

<!-- ################################################################################################ -->
<!-- ################################################################################################ -->
<!-- ################################################################################################ -->

<app-footer></app-footer>