import { Component, HostListener, OnInit } from "@angular/core";
import { Router, ActivatedRoute, NavigationExtras } from "@angular/router";
import { DepartmentService } from "../service/department.service";
import { Plan, AfcPlanDetails } from "../model/plan";

@Component({
  selector: "app-plan",
  templateUrl: "./plan.component.html",
  styleUrls: ["./plan.component.scss"],
})
export class PlanComponent implements OnInit {
  planDisplayId: string;
  plan: Plan = new Plan(0, "", "", "", 0);
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private departmentService: DepartmentService
  ) {
    route.queryParams.subscribe((param) => {
      this.reloadCurrentRoute();
    });
  }
  @HostListener("contextmenu", ["$event"])
  onRightClick(event) {
    event.preventDefault();
  }
  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.route.queryParams.forEach((params) => {
      this.planDisplayId = params["id_"];
      if (!this.planDisplayId || this.planDisplayId.length < 2) {
        this.router.navigate(["/home"]);
      } else {
        this.getPlanByDisplayUrl();
      }
    });
  }

  reloadCurrentRoute() {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }

  getPlanByDisplayUrl() {
    this.departmentService
      .getPlanByDisplayUrl(this.planDisplayId)
      .then((response) => {
        if (response.status) {
          this.plan = response.data;
        }
      });
  }
  navigatetoDepartment() {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        id_: this.plan.departmentDisplayUrl,
      },
    };
    this.router.navigate(["/department"], navigationExtras);
  }
  planDetailLink(planDetail: AfcPlanDetails) {
    if (!planDetail.detailsLink || planDetail.detailsLink.length < 2) {
      return;
    }
    if (planDetail.detailsLinkType == 1) {
      window.open(planDetail.detailsLink, "_blank");
    } else if (planDetail.detailsLinkType == 2) {
      window.open(planDetail.detailsLink, "_blank");
    }
  }
}
