import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApiService } from './api.service';
import { ComponentInteractionService } from './component-interaction.service';
import { ApiStatus } from '../model/common';
import { UrlConstants } from '../constants/url-constants';


@Injectable({
  providedIn: 'root'
})
export class AlbumService extends ApiService {

  constructor(private http: HttpClient, private cci: ComponentInteractionService) {
    super(http, cci);
  }

  getAllAlbums(): Promise<ApiStatus> {
    return this.doGet(UrlConstants.GET_ALL_ALBUMS__GET, false);
  }

  getAlbumByDisplayUrl(albumDisplayUrlId: string): Promise<ApiStatus> {
    return this.doGet(UrlConstants.GET_ALBUM_BY_ALBUM_DETAILS_ID__GET + `?albumDisplayUrlId=${albumDisplayUrlId}`, false);
  }

}