import { Component, OnInit } from '@angular/core';
import { DepartmentService } from '../service/department.service';
import { Constants } from '../constants/constants';
import { IfStmt } from '@angular/compiler';
import { NavigationExtras, Router } from '@angular/router';
import { Department } from '../model/department';
import { Plan } from '../model/plan';

@Component({
  selector: 'app-marquee-section',
  templateUrl: './marquee-section.component.html',
  styleUrls: ['./marquee-section.component.scss']
})
export class MarqueeSectionComponent implements OnInit {
  marqueList: any[] = [];
  hasMarque: boolean = false;

  constructor(private departmentService: DepartmentService, private router: Router) {

  }

  ngOnInit(): void {
    this.getPlanMarque();
  }

  getPlanMarque() {
    this.departmentService.getPlanMarque().then(response => {
      console.log(response);
      if (response.status) {
        this.marqueList = response.data;
        this.hasMarque = true;
      }
    }
    );

  };
  navigatetoPlan(marque: any) {
    console.log(marque);
    let navigationExtras: NavigationExtras = {
      queryParams: {
        "id_": marque.displayUrlId
      }
    };
    this.router.navigate(['/plan'], navigationExtras);
  }
}
