<app-title-section></app-title-section>
<!-- ################################################################################################ -->
<!-- ################################################################################################ -->
<!-- ################################################################################################ -->
<div class="wrapper bgded overlay" style="background-image:url('./assets/images/afc_head.jpg');">
    <app-marquee-section></app-marquee-section>
    <div id="breadcrumb" class="hoc clear">
        <!-- ################################################################################################ -->
        <h6 class="heading noselect">{{department.departmentName}}</h6>
        <ul>
            <li><a [routerLink]="['/home']">ഹോം</a></li>
            <li *ngIf="department.departmentTypeId==1"><a [routerLink]="['/home']">പദ്ധതികൾ</a></li>
            <li *ngIf="department.departmentTypeId==2"><a [routerLink]="['/home']">സ്‌കോളർഷിപ്പുകൾ</a></li>
            <li *ngIf="department.departmentTypeId==3"><a [routerLink]="['/home']">EWS Reservation</a></li>
            <li *ngIf="department.departmentTypeId==4"><a [routerLink]="['/home']">സർട്ടിഫിക്കറ്റുകൾ</a></li>
            <li *ngIf="department.departmentTypeId==5"><a [routerLink]="['/home']">E-District Services</a></li>
            <li *ngIf="department.departmentTypeId==6"><a href="javascript:void(0)">നോട്ടിഫിക്കേഷനുകൾ</a></li>
            <li *ngIf="department.departmentTypeId!=6"><a href="#">{{department.departmentName}}</a></li>
        </ul>
        <!-- ################################################################################################ -->
    </div>
</div>
<!-- ################################################################################################ -->
<!-- ################################################################################################ -->
<!-- ################################################################################################ -->
<!-- ################################################################################################ -->
<!-- ################################################################################################ -->
<!-- ################################################################################################ -->
<div class="wrapper row2 container-new">
    <section id="latest" class="hoc container clear">
        <!-- ################################################################################################ -->
        <div *ngIf="department.departmentTypeId!=6" class="sectiontitle">
            <h6 class="heading noselect">{{department.departmentName}}</h6>
            <p class="noselect" [innerHTML]="department.description"></p>

        </div>
        <ul class="nospace group">
            <li *ngFor="let plan of planList; let i = index" class="one_third" [class.first]="i%3 == 0"
                [class.not-first-row]="i>2">
                <article class="plan-li">
                    <figure><a href="javascript:void(0)" (click)="navigatetoPlan(plan)"><img
                                [src]="'/afc-restful/plan/image?planDisplayUrl='+plan.displayUrlId" alt=""></a>
                        <figcaption>
                            <!-- <time datetime="2045-04-06T08:15+00:00"><strong>06</strong> <em>Apr</em></time> -->
                        </figcaption>
                    </figure>
                    <div class="excerpt">
                        <h6 class="heading heading-text noselect">{{plan.planName}}</h6>
                        <!-- <ul class="nospace meta">
                            <li><i class="fas fa-user"></i> <a href="#">Admin</a></li>
                            <li><i class="fas fa-tags"></i> <a href="#">Tag 1</a>, <a href="#">Tag 2</a></li>
                        </ul> -->
                        <p [innerHTML]="plan.description" class="details-text noselect"></p>
                        <footer><a class="button footer-class" href="javascript:void(0)"
                                (click)="navigatetoPlan(plan)">Click here for more details
                            </a></footer>
                    </div>
                </article>
            </li>
        </ul>
        <!-- ################################################################################################ -->
    </section>
</div>
<!-- ################################################################################################ -->
<!-- ################################################################################################ -->
<!-- ################################################################################################ -->
<app-footer></app-footer>