export class Constants {
    /*
    * department types
    */
    public static DEPARTMENT_TYPE_SHCEMES: number = 1;
    public static DEPARTMENT_TYPE_SCHOLARSHIP: number = 2;
    public static DEPARTMENT_TYPE_EWS: number = 3;
    public static DEPARTMENT_TYPE_CERTIFICATES: number = 4;
    public static DEPARTMENT_TYPE_E_DISTRICT: number = 5;
    public static DEPARTMENT_TYPE_NOTIFICATIONS: number = 6;

}