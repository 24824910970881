export class Department {
    departmentId: number;
    departmentName: string;
    description: string;
    departmentTypeId: number;
    displayUrlId: string;

    constructor(departmentId: number, departmentName: string, displayUrlId: string,
        description: string, departmentTypeId: number, imagePath: string) {
        this.departmentId = departmentId;
        this.departmentName = departmentName;
        this.description = description;
        this.departmentTypeId = departmentTypeId;
        this.displayUrlId = displayUrlId;
    }
}
