export class UrlConstants {
  /*
   * Backend Project name
   */
  private static BACKEND_PROJECT_NAME: string = "/afc-restful/";
  /*
   * Department Controller
   */
  public static GET_DEPARTMENTS_BY_TYPE__GET: string =
    UrlConstants.BACKEND_PROJECT_NAME + "department/get-department";
  public static GET_DEPARTMENTS_BY_DISPLAY_URL__GET: string =
    UrlConstants.BACKEND_PROJECT_NAME +
    "department/get-department/by-display-url";
  /*
   * Plan Controller
   */
  public static GET_PLAN_BY_DEPARTMENT_DISPLAY_URL__GET: string =
    UrlConstants.BACKEND_PROJECT_NAME + "plan/get-by-department-display-url";
  public static GET_PLAN_BY_PLAN_DISPLAY_URL__GET: string =
    UrlConstants.BACKEND_PROJECT_NAME + "plan/get-by-display-url";
  public static GET_PLAN_BY_PLAN_MARQUEE__GET: string =
    UrlConstants.BACKEND_PROJECT_NAME + "plan/marquee";
  public static GET_LAST_ADDED_PLANS: string =
    UrlConstants.BACKEND_PROJECT_NAME + "plan/last-added";

  /*
   * Album Controller
   */
  public static GET_ALL_ALBUMS__GET: string =
    UrlConstants.BACKEND_PROJECT_NAME + "album";
  public static GET_ALBUM_BY_ALBUM_DETAILS_ID__GET: string =
    UrlConstants.BACKEND_PROJECT_NAME + "album/get-by-album-display-url";

  /*
   *
   */
}
