import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { HomeComponent } from "./home/home.component";
import { DepartmentComponent } from "./department/department.component";
import { PlanComponent } from "./plan/plan.component";
import { TitleSectionComponent } from "./title-section/title-section.component";
import { HttpClientModule } from "@angular/common/http";
import { FooterComponent } from "./footer/footer.component";
import { MarqueeSectionComponent } from "./marquee-section/marque-section.component";
import { DetailsPageComponent } from "./details-page/details-page.component";
import { AlbumImageComponent } from "./album-image/album-image.component";
import { AlbumComponent } from "./album/album.component";
import { AlbumImageViewComponent } from "./album-image-view/album-image-view.component";
import { PrivacyPolicyComponent } from "./privacy-policy/privacy-policy.component";

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    DepartmentComponent,
    PlanComponent,
    TitleSectionComponent,
    FooterComponent,
    MarqueeSectionComponent,
    DetailsPageComponent,
    AlbumImageComponent,
    AlbumComponent,
    AlbumImageViewComponent,
    PrivacyPolicyComponent,
  ],
  imports: [BrowserModule, AppRoutingModule, HttpClientModule],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
