<app-title-section></app-title-section>
<!-- ################################################################################################ -->
<!-- ################################################################################################ -->
<!-- ################################################################################################ -->
<div class="wrapper bgded overlay container-new" style="background-image:url('./assets/images/afc_head.jpg');">
    <div id="breadcrumb" class="hoc clear">
        <!-- ################################################################################################ -->
        <h6 class="heading">അതിരൂപത ഫെസിലിറ്റേഷൻ സെന്റർ</h6>
        <ul *ngIf="true">
            <li><a [routerLink]="['/home']">ഹോം</a></li>
            <!-- <li *ngIf="plan.departmentTypeId==1"><a [routerLink]="['/home']">പദ്ധതികൾ</a></li>
            <li *ngIf="plan.departmentTypeId==2"><a [routerLink]="['/home']">സ്‌കോളർഷിപ്പുകൾ</a></li>
            <li *ngIf="plan.departmentTypeId==3"><a [routerLink]="['/home']">EWS Reservation</a></li>
            <li *ngIf="plan.departmentTypeId==4"><a [routerLink]="['/home']">സർട്ടിഫിക്കറ്റുകൾ</a></li>
            <li *ngIf="plan.departmentTypeId==5"><a [routerLink]="['/home']">E-District Services</a></li> -->
            <!-- <li *ngIf="plan.departmentTypeId==6"><a href="javascript:void(0)"
                    (click)="navigatetoDepartment()">നോട്ടിഫിക്കേഷനുകൾ</a></li>
            <li *ngIf="plan.departmentTypeId!=6"><a href="javascript:void(0)"
                    (click)="navigatetoDepartment()">{{plan.departmentName}}</a></li> -->
            <li><a href="javascript:void(0)">അതിരൂപത ഫെസിലിറ്റേഷൻ സെന്റർ</a></li>
        </ul>
        <!-- ################################################################################################ -->
    </div>
</div>
<!-- ################################################################################################ -->
<!-- ################################################################################################ -->
<!-- ################################################################################################ -->
<!-- ################################################################################################ -->
<!-- ################################################################################################ -->
<!-- ################################################################################################ -->
<div class="wrapper row3">
    <main class="hoc  container clear">
        <!-- main body -->
        <!-- ################################################################################################ -->
        <div class="content">
            <!-- ################################################################################################ -->

            <div id="comments">
                <!-- <h3>അതിരൂപത ഫെസിലിറ്റേഷൻ സെന്റർ</h3> -->
                <p class="noselect">
                    2020 വർഷത്തിൽ കോവിഡിന്റെ ഒന്നാം തരംഗം അവസാനിച്ചപ്പോൾ കൂടിയ സംഘടന ഭാരവാഹികളുടെ അതിരൂപത തല യോഗത്തിലെ
                    പ്രധാന ചർച്ചാ വിഷയങ്ങളിൽ ഒന്നായിരുന്നു വിദ്യാഭ്യാസ- സാമൂഹിക-ക്ഷേമ മേഖലകളില്‍ ലഭിക്കുന്ന പൊതു
                    ആനുകൂല്യങ്ങളും സേവനങ്ങളും നേടിയെടുക്കുന്നതില്‍ ക്രൈസ്തവ സമൂഹം നേരിടുന്ന അവഗണനയും കാണിക്കുന്ന
                    വിമുഖതയും പരിഹരിക്കുക എന്നത്. വിവിധ മേഖലകളില്‍ പ്രവര്‍ത്തിക്കുന്ന ക്രൈസ്തവസംഘടനകള്‍ തങ്ങളുടെ
                    അംഗങ്ങളുടെ ക്ഷേമത്തിനായുള്ള പദ്ധതികള്‍ അവതരിപ്പിച്ചിട്ടും നേടിയെടുക്കുതിനായി പരിശ്രമിച്ചിട്ടും
                    വേണ്ടത്ര ക്രൈസ്തവ സമുദായാംഗങ്ങള്‍ ഇത് ഉപയോഗിക്കുന്നില്ല എന്ന വസ്തുത നിലനിൽക്കെ തന്നെ ന്യൂനപക്ഷ
                    അവകാശങ്ങൾ, വിവിധ സർക്കാർ ആനുകൂല്യങ്ങൾ, സ്‌കോളർഷിപ്പുകൾ എന്നിവ നേടിയെടുക്കാൻ തൃശ്ശൂർ അതിരൂപതാംഗങ്ങളെ
                    പ്രാപ്തരാക്കുക എന്ന ലക്ഷ്യത്തിനു വേണ്ടി പ്രവർത്തിക്കുന്ന ഒരു കൂട്ടായ്മ രൂപീകരിക്കുന്നതിനായി യോഗം
                    തീരുമാനിച്ചു. ഒരു മാസത്തിനു ശേഷം കൃത്യമായി പറഞ്ഞാൽ 2020 ഒക്ടോബർ 11നു ആ കൂട്ടായ്‌മ രൂപീകൃതമായി.
                    അതിരൂപത ഫെസിലിറ്റേഷൻ സെന്റർ അഥവാ എ. എഫ്. സി.
                </p>
                <h3 class="noselect"> ഘടന</h3>
                <p class="noselect"> തൃശൂര്‍ അതിരൂപത ഫാമിലി അപ്പസ്‌തൊലേറ്റിന്റെ കീഴില്‍ പ്രവര്‍ത്തിക്കുന്ന സംവിധാനമാണ്
                    അതിരൂപത
                    ഫെസിലിറ്റേഷന്‍ സെന്റര്‍. ചെയര്‍മാന്‍ അതിരൂപത സഹായമെത്രാന്‍ മാര്‍ ടോണി നീലങ്കാവില്‍, ഡയറക്ടര്‍ റവ.ഡോ.
                    ഡെന്നി താണിക്കല്‍ 9605043327. കണ്‍വീനര്‍ ജോമി ജോണ്‍സണ്‍ 9447769815, ജോ. കണ്‍വീനര്‍ ജെയിംസ്
                    ആഴചങ്ങാടന്‍ 9846142576, ബോര്‍ഡ് ഓഫ് കണ്‍സല്‍ട്ടേഴ്‌സ് ഡോ. മേരി റെജീന 9446130239, റോണി അഗസ്റ്റിന്‍
                    9946221499, ഓഫീസ് സെക്രട്ടറിമാര്‍ എന്‍.കെ. മാത്യു 7909293325, പി.ആര്‍. മേഴ്‌സി 7902713325 കൂടാതെ
                    ആനിമേറ്റേഴ്‌സ് ആയി കുടുംബ കൂട്ടായ്മ കേന്ദ്രസമിതി, എ.കെ.സി.സി, കെ.സി.വൈ.എം, സി.എല്‍.സി, ജീസസ് യൂത്ത്,
                    കെ.എല്‍.എം, വിശ്വാസ പരിശീലനം, ടീച്ചേഴ്‌സ് ഗില്‍ഡ്, സാന്ത്വനം, മാതൃവേദി എന്നീ സംഘടനകളുടെ അതിരൂപത
                    പ്രതിനിധികളും ഉള്‍പ്പെടുന്നതാണ് അതിരൂപത ഫെസിലിറ്റേഷന്‍ സെന്ററിന്റെ ഗെവേര്‍ണിങ്ങ് ബോഡി. അതിരൂപത
                    ഫാമിലി അപ്പസ്‌തൊലേറ്റ് കേന്ദ്രത്തില്‍ ആണ് എ. എഫ്. സിയുടെ ഓഫീസ് സ്ഥിതി ചെയ്യുന്നത്.
                    രൂപത തലത്തിലും ഇടവക തലത്തിലും പ്രവർത്തനം ഏകോപിപ്പിക്കുന്നതിലൂടെ മാത്രമേ ഗുണഭോതാക്കളിലേക്ക് വിവിധ
                    ആനുകൂല്യങ്ങൾ എത്തിക്കുവാൻ സാധിക്കുകയുള്ളു എന്നതിനാൽ തന്നെ ഓരോ ഇടവകയിലും ഒരു ഹെല്‍പ് ഡെസ്‌ക് ( പാരിഷ്
                    ഫെസിലിറ്റേഷൻ സെന്റർ അഥവാ പി. എഫ്. സി.) രൂപീകരിച്ചു. ആദ്യ ഘട്ടമെന്നോണം നിലവിൽ 20 ഇടവകകളിലാണ് പി. എഫ്.
                    സി പ്രവർത്തനം നടക്കുന്നത്. എ. എഫ്. സി. കണ്ടെത്തുന്ന ഓരോ സര്‍ക്കാര്‍ പദ്ധതികളും ഇടവക ജനങ്ങളിലേക്ക്
                    പി. എഫ്. സി വഴി നേരിട്ടെത്തിക്കുകയും അവ നേടിയെടുക്കുന്നതിനായി സഹായിക്കുകയും ചെയ്യുന്നു.
                </p>
                <h3 class="noselect">
                    പ്രവർത്തന ശൈലി
                </h3>
                <p class="noselect">
                    വിവിധ സർക്കാർ വകുപ്പുകളും പ്രഖ്യാപിക്കുന്ന പൊതു ആനുകൂല്യങ്ങളും പ്രത്യേകമായി ന്യൂനപക്ഷങ്ങൾക്ക് വേണ്ടി
                    മാത്രമുള്ള ആനുകൂല്യങ്ങളും സ്കോളര്ഷിപ്പുകളും, വായ്പ പദ്ധതികളും സാധാരണക്കാരിലേക്ക്
                    എത്തിക്കുന്നത്തിനായി ഇവ വിവിധ പ്രൊജെക്ടുകൾ ആക്കി മാറ്റുന്നു. വിദഗ്ധ സമിതി ഇവ വിലയിരുത്തി
                    <a href="https://www.afctcr.com">www.afctcr.com</a> എന്ന വെബ്‌സൈറ്റില്‍ പദ്ധതിയെപ്പറ്റിയുള്ള
                    വിശദവിരങ്ങള്‍, അപേക്ഷിക്കുന്നതിനുള്ള
                    ഓണ്‍ലൈന്‍ ലിങ്ക്, അനുബന്ധ ഫോമുകള്‍ എന്നിവ അപ്പ്‌ലോഡ് ചെയ്യുകയും ചെയ്യുന്നു. പരിചയപ്പെടുത്തുന്ന ഓരോ
                    പദ്ധതികളെയും കുറിച്ചുള്ള അറിയിപ്പ്, വെബ്‌സൈറ്റ് ലിങ്ക്, ഓഫീസ് സെക്രട്ടറിമാരുടെ ഫോണ്‍നമ്പര്‍ എന്നിവ
                    ഉള്‍പ്പെടുത്തി വാട്ട്‌സ്ആപ്പ് മെസേജുകളിലൂടെ വിവിധ ഗ്രൂപ്പുകളിലേക്ക് അയയ്ക്കുന്നു. ഇടവക വികാരിമാർ,
                    കുടുംബകൂട്ടായ്‌മ -സംഘടന ഭാരവാഹികൾ, അൽമായ നേതാക്കൾ, പി. എഫ്. സി സെക്രട്ടറിമാർ തുടങ്ങി അയ്യായിരത്തോളം
                    പേർക്ക് നിലവിൽ നേരിട്ട് ഈ സന്ദേശം അയച്ചുവരുന്നു. കൂടാതെ എല്ലാ മാസവും കത്തോലിക്കാ സഭ പത്രത്തിലൂടെയും
                    വിവിധ പദ്ധതികൾ പരിചയപ്പെടുത്തുന്നു. അതുവഴി അതിരൂപത മുഴുവനിലേക്കും സർക്കാർ പദ്ധതിയെ പറ്റിയുള്ള മുഴുവൻ
                    വിവരങ്ങളും എത്തിച്ചേരുന്നു. പദ്ധതിയെപ്പറ്റിയുള്ള വിശദവിവരങ്ങള്‍ വെബ്‌സൈറ്റില്‍ നല്‍കുന്നതിനാല്‍
                    കമ്പ്യൂട്ടര്‍ പരിജ്ഞാനമുള്ള ആര്‍ക്കും എവിടെ നിന്നു വേണമെങ്കിലും സേവനങ്ങള്‍ ഉപയോഗപ്പെടുത്താവുന്നതാണ്.
                </p>
                <h3 class="noselect">
                    നാഴിക കല്ലുകൾ
                </h3>
                <p class="noselect">
                    വെല്‍ഫെയര്‍ സ്‌കീമില്‍ ഉള്‍പ്പെടാത്ത അഗതി മന്ദിരങ്ങള്‍, ആശ്രമങ്ങള്‍, മഠങ്ങള്‍, ചാരിറ്റബിള്‍
                    സ്ഥാപനങ്ങള്‍ എന്നിവിടങ്ങളില്‍ താമസിക്കുന്ന കന്യാസ്ത്രീകളേയും വൈദികരേയും അഗതികളെയും അന്തേവാസികളെയും
                    പ്രത്യേക വിഭാഗത്തിള്‍ ഉള്‍പ്പെടുത്തി ഇവര്‍ക്ക് അഞ്ചാമത് ഒരു പുതിയ വിഭാഗം റേഷന്‍ കാര്‍ഡ്
                    അനുവദിക്കുന്നതിന് സംസ്ഥാന സര്‍ക്കാര്‍ ഇക്കഴിഞ്ഞ ഫെബ്രുവരി മാസം ഉത്തരവിറക്കിയിരുന്നു. ഫെബ്രുവരി 18ന്‌
                    അതിരൂപത ഫെസിലിറ്റേഷന്‍ സെന്റര്‍ ഗുണഭോക്താക്കള്‍ക്ക് റേഷന്‍ കാര്‍ഡ് നേടിയെടുക്കുന്നതിനുള്ള ഹെല്‍പ്
                    ഡെസ്‌ക് ആരംഭിച്ചതിലൂടെ ഒരു മാസത്തിനകം 2248 പേര്‍ക്ക് റേഷന്‍ കാര്‍ഡിനുള്ള അപേക്ഷ ഓണ്‍ലൈന്‍ ആയി
                    സമര്‍പ്പിച്ച് റേഷന്‍കാര്‍ഡ് വാങ്ങി നല്‍കുവാന്‍ സാധിച്ചു. ദിവസവും രാവിലെ 8.30 മുതല്‍ രാത്രി 11.00 വരെ
                    35 ഓളം കമ്പ്യൂട്ടറുകളുടെ സഹായത്തോടെ ഹെല്‍പ് ഡെസ്‌ക്കിന്റെ പ്രവര്‍ത്തനങ്ങള്‍ വളരെ വേഗത്തില്‍
                    പൂര്‍ത്തിയാക്കി. 25 ഓളം യുവജനങ്ങളും 10 ഓളം കന്യാസ്ത്രീകളും ഫാമിലി അപ്പസ്‌തൊലേറ്റ് സ്റ്റാഫും
                    ആത്മാര്‍ത്ഥമായി ഹെല്‍പ് ഡെസ്‌ക്കുകളില്‍ സഹായികളായി. ഇന്നും പ്രവർത്തനം തുടരുന്ന റേഷൻ കാർഡ് ഹെല്പ്
                    ഡെസ്കിലൂടെ നിലവിൽ മൂവായിരത്തിലധികം പേർക്ക് റേഷൻകാർഡ് ലഭിച്ചു.
                    അതിരൂപത ഫെസിലിറ്റേഷന്‍ സെന്റര്‍ ഏറ്റെടുത്ത മറ്റൊരു പ്രധാന പ്രവർത്തനമാണ് സീറോ മലബാർ ക്രൈസ്തവരായ
                    നമുക്ക് കൂടി സർക്കാർ അനുവദിച്ചു നൽകിയ EWS റിസർവേഷൻ. സര്‍ക്കാര്‍ ജോലികളും, പ്ലസ് വണ്‍ മുതലുള്ള
                    വിദ്യാര്‍ത്ഥി പ്രവേശനങ്ങളിലും 10% സംവരണം ഏര്‍പ്പെടുത്തിയിരിക്കുന്നു എന്ന ആനുകൂല്യം അറിയുവാൻ ഭൂരിപക്ഷ
                    ക്രൈസ്തവരും വൈകിയപ്പോൾ അതിരൂപതയിലെ വിശ്വാസ പരിശീലന യൂണിറ്റുകളുടെ സഹകരണത്തോടെ വിവിധ ഫൊറോനകൾക്കായി
                    പത്തോളം ഓൺലൈൻ സെമിനാറിലൂടെ EWS റിസർവേഷനെ പരിചയപ്പെടുത്തി. പ്ലസ് വൺ, പ്ലസ് ടു ,എ സി സി ക്‌ളാസിൽ
                    പഠിക്കുന്നവവരും മറ്റു യുവജനങ്ങളുമായി ഏകദേശം രണ്ടായിരത്തോളം പേർ ഇതിനെ പറ്റിയറിയുകയും നൂറുകണക്കിന് പേർ
                    ഈ ആനുകൂല്യം നേടിയെടുക്കുയും ചെയ്തു. ഇന്ന് ഈ സംവരണത്തിലൂടെ ഇന്ന് സർക്കാർ ജോലി, എം .ബി.ബി.എസ്, എം.ബി.എ
                    മുതൽ പ്ലസ് വൺ അഡ്മിഷൻ നേടിയവരിൽ അല്മയരും വൈദികരും ഉൾപ്പെടുന്നു എന്നതിൽ എ .എഫ് . സി അഭിമാനിക്കുന്നു.
                </p>
                <h3 class="noselect">
                    ലക്ഷ്യം
                </h3>
                <p class="noselect">
                    അതിരൂപത ഫെസിലിറ്റേഷൻ സെന്റർ പ്രവർത്തനം ഒരു വർഷം തികയുമ്പോൾ ഒത്തിരി ലക്ഷ്യങ്ങൾ
                    കൈവരിക്കേണ്ടതായിട്ടുണ്ട്. അതിരൂപതയിലെ എല്ലാ ഇടവകകളിലും പി എഫ് സി സ്ഥാപിച്ചു അതിലൂടെ നമുക്ക് നിലവിൽ
                    ലഭ്യമായിട്ടുള്ള സംവരണങ്ങളും, ആനുകൂല്യങ്ങളും, സ്കോളർഷിപ്പുകളും ഗുണഭോക്താക്കളിലേക്ക് എത്തിക്കുന്നതിനും
                    അവ നേടിയെടുക്കാൻ ഒപ്പം നിൽക്കുന്നതിനുമാണ് പ്രഥമ പരിഗണന. സര്‍ക്കാര്‍ ഉദ്യോഗങ്ങള്‍ നേടിയെടുക്കാന്‍
                    പ്രോത്സാഹിപ്പിക്കുക, അതിനായി പിഎസ്‌സി - എംപ്ലോയ്‌മെന്റ് എക്സ്ചേഞ്ച് രജിസ്‌ട്രേഷൻ നടത്തിക്കുക, EWS
                    സംവരണം, സാമൂഹിക ക്ഷേമ പദ്ധതികള്‍, ന്യൂനപക്ഷ സ്‌കോളര്‍ഷിപ്പുകള്‍, ക്ഷേമ പദ്ധതികള്‍, വില്ലേജ്തല
                    സര്‍ട്ടിഫിക്കറ്റുകള്‍, ഗ്രാമപഞ്ചായത്ത്/ മുനിസിപാലിറ്റി/കോര്‍പറേഷന്‍തല അപേക്ഷകള്‍, കൃഷി വകുപ്പ്
                    ആനുകൂല്യങ്ങള്‍, ആധാര്‍, പാന്‍കാര്‍ഡ്, വോട്ടര്‍ കാര്‍ഡ് സേവനങ്ങള്‍ തുടങ്ങി ഒരു പൗരനു സ്വയം
                    അപേക്ഷിക്കാവുന്നതും നേടിയെടുക്കാവുന്നതുമായ എല്ലാ സേവനങ്ങളിലും അവൻ്റെ കൂടെ ഒരു കൈത്താങ്ങായ്
                    നിലകൊള്ളുക. അതുവഴി വ്യക്തിയുടെയും ഇടവകയുടെയും സഭയുടെയും ഉന്നമനത്തിനായി പ്രവർത്തിക്കുക. അതിരൂപതയിലെ
                    കുടുംബകൂട്ടായ്മകളുടെയും വിവിധ സംഘടനകളുടെയും സഹകരണത്തോടെ ഈ ലക്ഷ്യങ്ങൾ കൈവരിക്കാൻ എ എഫ് സിക്ക്
                    കഴിയട്ടെ..

                </p>


            </div>
            <!-- ################################################################################################ -->
        </div>
        <!-- ################################################################################################ -->
        <div class="clear"></div>
    </main>
</div>

<app-footer></app-footer>