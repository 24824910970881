<app-title-section></app-title-section>
<div class="wrapper bgded overlay background-image-class container-new"
    style="background-image:url('./assets/images/backgrounds/afc.jpg');">
    <!-- <app-marquee-section></app-marquee-section> -->
</div>
<!-- ########################################################################################## -->
<div style='padding:10px'>
    <h3 style='text-align: center;margin-top:20px'> PRIVACY POLICY & NEWS POLICY</h3>
    <hr class="soft" />
    <p style='text-align: justify;text-justify: inter-word;'>
        AFC respects your privacy and recognizes the need to protect your personal information
        (any information by which you can be identified, such as name, address, financial information, and
        telephone number) you share with us. We would like to assure you that we follow appropriate
        standards when it comes to protecting your privacy on our web sites and applications.
    </p>
    <p style='text-align: justify;text-justify: inter-word;'> In general, you can visit AFC without telling us
        who you are or revealing any personal
        information about yourself. We track the Internet address of the domains from which people visit us
        and analyze this data for trends and statistics, but the individual user remains anonymous.
    </p>
    <p style='text-align: justify;text-justify: inter-word;'>
        A transaction on AFC is to be conducted by persons above the age of 13 years only. It is the duty
        of the legal guardians of all persons below 13 years of age to ensure that their wards do not make use of AFC
        functionalities.
        It shall be automatically deemed that by
        allowing any person below the age of 13 years to transact on AFC, their legal guardians
        have expressly consented to their use and we disclaim any liability arising from your failure to do
        so.
    </p>

    <p style='text-align: justify;text-justify: inter-word;'>
        AFC is not collecting any personal data from the users.
        <br /> When you access AFC, we will automatically collect your
        computer data, which may include:
    </p>
    <p style='text-align: justify;text-justify: inter-word;'>
        a) browser type
        <br> b) IP address
        <br> c) language
        <br> d) operating system
        <br> e) cookies and the ID and location of your device
        <br> f) the state or country from which you accessed AFC
        <br> g) the pages/videos you view
        <br> h) length of time spent on pages/videos
        <br> i) the services you use and the manner in which you use such services (e.g., the content you
        access, view, click on, search for, transact etc.)
        <br> j) the date and time of your visit
        <br> k) metadata, logs files, error logs
        <br> l) which pop up or push messages you might have seen and responded to
    </p>
    <p style='text-align: justify;text-justify: inter-word;'>This computer data is collected for analysis and evaluation
        in order to help us improve AFC,
        the services we provide and to enhance your experience by providing you with better services and
        benefits that you shall be entitled to.</p>

    <p style='text-align: justify;text-justify: inter-word;'>
        AFC will not share any of your data with advertising networks and/or social media platforms for the purposes of
        selecting and serving relevant adverts to you via those networks/platforms, and to search engine and
        analytics providers.
    </p>
    <p style='text-align: justify;text-justify: inter-word;'>
        We have implemented reasonable security arrangements including physical, administrative, technical,
        and electronic security measures to protect against the loss, misuse, and alteration of your
        personal data. We are PCI DSS certified which means that the data you submit to us is secure and
        protected against loss or theft in accordance with the globally accepted data security standards.
        Despite our best efforts, however, no security measures are perfect or impenetrable. In the event
        where you believe your privacy has been breached, please contact us immediately.
    </p>
    <p style='text-align: justify;text-justify: inter-word;'>
        Some of our web pages use "cookies" so that we can better serve you with customized information when
        you return to our site. Cookies are identifiers which a web site can send to your browser to keep on
        your computer to facilitate your next visit to our site. You can set your browser to notify you when
        you are sent a cookie, giving you the option to decide whether or not to accept it. The information
        we collect and analyze is used to improve our service to you. Please note, if you refuse cookies
        this may mean that you can't use some of the additional features of our website and may not be able
        to access certain parts of the website.
    </p>
    <!-- #################################################################### -->
    <!-- #################################################################### -->
    <!-- #################################################################### -->
    <p style="font-size: 20px; font-weight: bold; text-align: left;margin-top:20px"> AFC STRUCTURE</p>
    <p style='text-align: justify;text-justify: inter-word;'>
        Archdiocesan Facilitation Center (AFC) is a structure that works under Family Apostolate
        <a href="https://www.trichurfamilyapostolate.org" target="_blank"> (www.trichurfamilyapostolate.org)
        </a> that collects and publishes the news regarding Minority
        Entitlements, various government benefits, scholarships etc..
        <br />
    </p>
    <p style="font-size: 16 px; font-weight: bold;">News are collected and processed by the following Editorial Board
    </p>
    <b>Chief Patron</b>: Mar Andrews Thazhath (Archbishop of Thrissur Archdiocese)
    <br />
    <b>Patron</b>: Mar Tony Neelamkavil (Auxiliary Bishop of Thrissur Archdiocese)
    <br />
    <b>Chief Editor</b>: Rev. Dr. Denny Thanikal (9605043327)
    <br />
    <b>Editor</b>: Sri. Jomy Johnson (9447769815)
    <br />
    <b>Sub Editor</b>: Sri. James Arachangadan (9846142576)
    <br />
    <b>Editor Board Member</b>: Dr. Mary Regina (9446130239)
    <br />
    <b>Editor Board Member</b>: Sri. Ronie Augustine (9946221499)
    <br />
    <br />

    <!-- ################################################################################################ -->
    <!-- ################################################################################################ -->
    <!-- ################################################################################################ -->
    <!-- ################################################################################################ -->
    <div class="wrapper gradient">
        <section id="testimonials" class="hoc container clear pator-members-section">
            <!-- ################################################################################################ -->
            <div class="sectiontitle">
                <h6 class="heading">AFC Editorial Board</h6>
                <!-- <p>In egestas vitae turpis sed velit urna sollicitudin ut euismod</p>  -->
            </div>
            <article class="one_half first"><img class="savors-image" src="./assets/images/mar_andrews_thazhath.jpg"
                    alt="">
                <h6 class="heading">Mar Andrews Thazhath</h6>
                <em>Chief Patron</em>
            </article>
            <article class="one_half"><img class="savors-image" src="./assets/images/mar_tony_neelamkavil.jpg" alt="">
                <h6 class="heading">Mar Tony Neelamkavil</h6>
                <em>Patron</em>
            </article>
            <!-- ################################################################################################ -->
        </section>
        <section class="hoc container clear commitee-members-section">
            <!-- ################################################################################################ -->
            <!-- <div class="sectiontitle"> -->
            <!-- <h6 class="heading">എ ഫ് സി ഭാരവാഹികൾ </h6> -->
            <!-- <p>Vestibulum nisi ut lectus proin lectus ante fermentum sed</p> -->
            <!-- </div> -->
            <div class="group center">
                <article class="one_third first"><a class="ringcon btmspace-50" href="#"><img
                            class="savors-image image-round" src="./assets/images/fr_denny.jpg" alt=""></a>
                    <h6 class="heading commitee-members-heading">Rev. Dr. Denny Thanikal </h6>
                    <p class="commitee-members-p">Chief Editor</p>
                    <p class="commitee-members-phone">9605043327</p>
                </article>
                <article class="one_third"><a class="ringcon btmspace-50" href="#"><img class="savors-image image-round"
                            src="./assets/images/jomy_johnson.jpg" alt=""></a>
                    <h6 class="heading commitee-members-heading">Sri. Jomy Johnson</h6>
                    <p class="commitee-members-p">Editor</p>
                    <p class="commitee-members-phone">9447769815</p>
                </article>
                <article class="one_third"><a class="ringcon btmspace-50" href="#"><img class="savors-image image-round"
                            src="./assets/images/james_aazchangadan.jpg" alt=""></a>
                    <h6 class="heading commitee-members-heading"> Sri. James Arachangadan</h6>
                    <p class="commitee-members-p">Sub Editor</p>
                    <p class="commitee-members-phone">9846142576</p>

                </article>
            </div>
            <!-- ################################################################################################ -->
        </section>

        <section class="hoc container clear commitee-members-section">
            <div class="group center">
                <article class="one_half first"><a class="ringcon btmspace-50" href="#"><img
                            class="savors-image image-round" src="./assets/images/dr_mary_regeena.jpg" alt=""></a>
                    <h6 class="heading commitee-members-heading">Dr. Mary Regina </h6>
                    <p class="commitee-members-p">Editor Board Member</p>
                    <p class="commitee-members-phone">9446130239</p>

                </article>
                <article class="one_half"><a class="ringcon btmspace-50" href="#"><img class="savors-image image-round"
                            src="./assets/images/rony_agastin.jpg" alt=""></a>
                    <h6 class="heading commitee-members-heading">Sri. Ronie Augustine</h6>
                    <p class="commitee-members-p">Editor Board Member</p>
                    <p class="commitee-members-phone">9946221499</p>
                </article>

            </div>
            <!-- ################################################################################################ -->
        </section>

    </div>
    <!-- ################################################################################################ -->
    <!-- ################################################################################################ -->
    <!-- ################################################################################################ -->
    <!-- ################################################################################################ -->




    <p style="font-size: 20px; font-weight: bold; text-align: left;margin-top:20px"> AFC Working Style</p>
    <p style='text-align: justify;text-justify: inter-word;'>
        Various Benefits, Minority
        Entitlements and Scholarships declared by the different government departments are evaluated by the expert
        editorial committee, and are converted into different AFC Projects. The main sources of news are the
        following </p>
    <ul>
        <li>Kerala PSC - <a href="https://www.keralapsc.gov.in/" target="_blank">keralapsc.gov.in</a></li>

        <li>Natioal Scholarship Portal - <a href="https://scholarships.gov.in" target="_blank">scholarships.gov.in</a>
        </li>

        <li>Minority Welfare Kerala - <a href="http://www.minoritywelfare.kerala.gov.in/"
                target="_blank">minoritywelfare.kerala.gov.in</a>
        </li>

        <li>Social Justice Department - <a href="http://swd.kerala.gov.in/" target="_blank">swd.kerala.gov.in</a>
        </li>

        <li>Information & Public Relations Department - I&PRD - <a href="https://prd.kerala.gov.in/"
                target="_blank">prd.kerala.gov.in</a></li>

        <li>Railway Recruitment Boards (RRBs) - <a href="https://indianrailways.gov.in"
                target="_blank">indianrailways.gov.in</a></li>

        <li>Sashastra Seema Bal (SSB) - <a href="http://www.ssbrectt.gov.in" target="_blank">ssbrectt.gov.in</a></li>

        <li>Institute of Banking Personnel Selection (IBPS) - <a href="https://www.ibps.in/"
                target="_blank">www.ibps.in</a></li>
    </ul>

    <p>These projects are added to the AFC Website with related forms and online link. These project details are
        circulated among the public along with website links and the details of different office secretaries.
    </p>
    <p>For any queries,</p>
    <p> <a class="icon-spacing" href="mailto:info@afctcr.com"> <i class="fas fa-envelope"></i></a>
        <span><strong>Email:</strong>
            info@afctcr.com<br /> </span>
    </p>
    <p>
        <a class="icon-spacing" href="tel:+917902713325"><i class="fas fa-phone"></i></a>
        <span><strong>Phone:</strong> +91 7902713325</span>
    </p>
    <p>
        <a class="icon-spacing" href="tel:+917909293325"><i class="fas fa-phone"></i></a>
        <span><strong>Phone:</strong> +91 7909293325</span>
    </p>
    <p>
        <a class="icon-spacing" href="tel:+917909293325"><i class="fas fa-home"></i></a>
        <span><strong>Address:</strong> Family Apostolate Center, Kizhakkumpattukara, Thrissur, Kerala</span>
    </p>
    <!-- #########################################################################################33 -->
    <p style='text-align: justify;text-justify: inter-word;'>
        If our privacy policy changes in the future, it will be posted here and a new effective date will be
        shown. You should access our privacy policy regularly to ensure you understand our current policies.
        Please reference the privacy policy in your subject line. AFC will attempt to respond to
        all reasonable concerns or inquiries within 30 business days of receipt.
    </p>
</div>
<!-- ################################################################################################ -->
<!-- ################################################################################################ -->
<!-- ################################################################################################ -->

<app-footer></app-footer>