import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { AlbumService } from "../service/album.service";

@Component({
  selector: "app-album",
  templateUrl: "./album.component.html",
  styleUrls: ["./album.component.scss"],
})
export class AlbumComponent implements OnInit {
  albumList: any[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private albumService: AlbumService
  ) {
    route.queryParams.subscribe((param) => {
      this.reloadCurrentRoute();
    });
  }
  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.getAllAlbums();
  }

  reloadCurrentRoute() {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }

  navigatetoAlbumDetails(album) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        id_: album.displayUrlId,
      },
    };
    this.router.navigate(["/photo-gallery"], navigationExtras);
  }

  getAllAlbums() {
    this.albumService.getAllAlbums().then((response) => {
      if (response.status) {
        this.albumList = [];
        response.data.forEach((element) => {
          if (element.albumDescription && element.albumDescription.length > 100)
            element.albumDescription =
              element.albumDescription.substring(0, 50) + "...";
          this.albumList.push(element);
        });
      }
    });
  }
}
