import { Component, HostListener, OnInit } from "@angular/core";
import { DepartmentService } from "../service/department.service";
import { Department } from "../model/department";
import { NavigationExtras, Router } from "@angular/router";
import { Plan } from "../model/plan";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
})
export class HomeComponent implements OnInit {
  departmentList: Department[] = [];
  planList: Plan[] = [];

  constructor(
    private departmentService: DepartmentService,
    private router: Router
  ) {}

  ngOnInit(): void {
    // this.getDepartmentsByType(0);
    this.getLastAddedPlans();
    window.scrollTo(0, 0);

    console.log(" height : " + window.screen.height);
    console.log(" width : " + window.screen.width);
    console.log(" innerHeight : " + window.innerHeight);
    console.log(" innerWidth : " + window.innerWidth);
  }
  @HostListener("contextmenu", ["$event"])
  onRightClick(event) {
    event.preventDefault();
  }
  getDepartmentsByType(departmentTypeId: number) {
    this.departmentService
      .getDepartmentsByType(departmentTypeId)
      .then((response) => {
        if (response.status) {
          this.departmentList = [];
          response.data.forEach((element) => {
            if (element.description && element.description.length > 100)
              element.description =
                element.description.substring(0, 100) + "...";
            this.departmentList.push(element);
          });
        }
      });
  }
  getLastAddedPlans() {
    this.departmentService.getLastAddedPlans().then((response) => {
      if (response.status) {
        this.planList = [];
        response.data.forEach((element) => {
          if (element.description.length > 90)
            element.description = element.description.substring(0, 90) + "...";
          if (element.planName.length > 65)
            element.planName = element.planName.substring(0, 65) + "...";
          this.planList.push(element);
        });
      }
    });
  }
  navigatetoPlan(plan: Plan) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        id_: plan.displayUrlId,
      },
    };
    this.router.navigate(["/plan"], navigationExtras);
  }
  navigatetoDepartment(department: Department) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        id_: department.displayUrlId,
      },
    };
    this.router.navigate(["/department"], navigationExtras);
  }
  navigateToDetails() {
    this.router.navigate(["/details"]);
  }
}
