<div class="wrapper row5">
    <div id="copyright" class="hoc clear">
        <!-- ################################################################################################ -->
        <p class="fl_left"><a href="tel:+917902713325"><i class="fas fa-phone"></i></a>
            <span> (+91) 7902713325,7909293325</span>
        </p>

        <p class="fl_right">Copyright &copy; 2022 - All Rights Reserved <a href="#"></a></p>
        <p class="fl_right"><a class="privacy-policy" [routerLink]="['/privacy-policy']">Terms And
                Conditions</a></p>
        <!-- ################################################################################################ -->
    </div>
</div>
<!-- ################################################################################################ -->
<!-- ################################################################################################ -->
<!-- ################################################################################################ -->
<a id="backtotop" href="#top"><i class="fas fa-chevron-up"></i></a>