export class Plan {
    planId: number;
    planName: string;
    description: string;
    lastDate: string;
    ageDetails: string;

    departmentId: number;
    departmentName: string;
    departmentTypeId: number;
    departmentDisplayUrl: string;

    displayUrlId: string;
    conditionsList: AfcPlanDetails[];
    documentsList: AfcPlanDetails[];
    placeList: AfcPlanDetails[];
    processList: AfcPlanDetails[];
    linkList: AfcPlanDetails[];
    lastUpdate: string;
    constructor(departmentId: number, departmentName: string, displayUrlId: string,
        description: string, departmentTypeId: number) {
        this.departmentId = departmentId;
        this.departmentName = departmentName;
        this.description = description;
        this.departmentTypeId = departmentTypeId;
        this.displayUrlId = displayUrlId;
    }
}
export class AfcPlanDetails {

    details: string;
    detailsLink: string;
    detailsLinkType: number;
    subDetail: AfcPlanDetails[];
}
