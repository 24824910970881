<app-title-section></app-title-section>
<!-- ################################################################################################ -->
<!-- ################################################################################################ -->
<!-- ################################################################################################ -->
<div class="wrapper bgded overlay container-new" style="background-image:url('./assets/images/afc_head.jpg');">
    <app-marquee-section></app-marquee-section>
    <div id="breadcrumb" class="hoc clear">

        <!-- ################################################################################################ -->
        <h6 class="heading noselect">{{plan.planName}}</h6>
        <ul *ngIf="plan">
            <li><a [routerLink]="['/home']">ഹോം</a></li>
            <li *ngIf="plan.departmentTypeId==1"><a [routerLink]="['/home']">പദ്ധതികൾ</a></li>
            <li *ngIf="plan.departmentTypeId==2"><a [routerLink]="['/home']">സ്‌കോളർഷിപ്പുകൾ</a></li>
            <li *ngIf="plan.departmentTypeId==3"><a [routerLink]="['/home']">EWS Reservation</a></li>
            <li *ngIf="plan.departmentTypeId==4"><a [routerLink]="['/home']">സർട്ടിഫിക്കറ്റുകൾ</a></li>
            <li *ngIf="plan.departmentTypeId==5"><a [routerLink]="['/home']">E-District Services</a></li>
            <li *ngIf="plan.departmentTypeId==6"><a href="javascript:void(0)"
                    (click)="navigatetoDepartment()">നോട്ടിഫിക്കേഷനുകൾ</a></li>
            <li *ngIf="plan.departmentTypeId!=6"><a href="javascript:void(0)"
                    (click)="navigatetoDepartment()">{{plan.departmentName}}</a></li>
            <li><a href="javascript:void(0)">{{plan.planName}}</a></li>
        </ul>
        <!-- ################################################################################################ -->
    </div>
</div>
<!-- ################################################################################################ -->
<!-- ################################################################################################ -->
<!-- ################################################################################################ -->
<!-- ################################################################################################ -->
<!-- ################################################################################################ -->
<!-- ################################################################################################ -->
<div class="wrapper row3 container-new">
    <main class="hoc  container clear">
        <!-- main body -->
        <!-- ################################################################################################ -->
        <div class="content">
            <!-- ################################################################################################ -->

            <div id="comments">
                <!-- <h2>{{plan.planName}}</h2> -->
                <address class="last-update-div noselect">
                    <a href="javascript:void(0)">Last Updated On : </a>
                    <span [innerHTML]="plan.lastUpdate"> </span>
                </address>
                <p [innerHTML]="plan.description" class="noselect"> </p>
                <ul>
                    <li *ngIf="plan.ageDetails&&plan.ageDetails.length>0">
                        <article>
                            <header>
                                <address>
                                    <a class="noselect" href="javascript:void(0)">പ്രായപരിധി :
                                    </a>
                                    <span class="noselect" [innerHTML]="plan.ageDetails"> </span>
                                </address>
                            </header>
                        </article>
                    </li>
                    <li *ngIf="plan.lastDate&&plan.lastDate.length>0">
                        <article>
                            <header>
                                <address>
                                    <a class="noselect" href="javascript:void(0)">അപേക്ഷ സമർപ്പിക്കേണ്ട അവസാന
                                        തിയതി : </a>
                                    <span class="noselect" [innerHTML]="plan.lastDate"> </span>
                                </address>
                            </header>
                        </article>
                    </li>
                    <li *ngIf="plan.conditionsList&&plan.conditionsList.length>0">
                        <article>
                            <header>
                                <address>
                                    <a class="noselect" href="javascript:void(0)">നിബന്ധനകൾ</a>
                                </address>
                            </header>
                            <div class="comcont">
                                <ol>
                                    <li class="plans-li" *ngFor="let planDetails of plan.conditionsList">
                                        <span class="noselect" [innerHTML]="planDetails.details"
                                            [class.link-open]="planDetails.detailsLinkType>0"
                                            [class.cursor-pointer]="planDetails.detailsLinkType>0"
                                            (click)="planDetailLink(planDetails)">
                                        </span>
                                        <div *ngIf="planDetails.subDetails">
                                            <ul class="ul-listing">
                                                <li class="plans-li" *ngFor="let subDetail of planDetails.subDetails">
                                                    <span class="noselect" [innerHTML]="subDetail.details"
                                                        [class.link-open]="subDetail.detailsLinkType>0"
                                                        [class.cursor-pointer]="subDetail.detailsLinkType>0"
                                                        (click)="planDetailLink(subDetail)">
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                </ol>
                            </div>
                        </article>
                    </li>
                    <li *ngIf="plan.documentsList&&plan.documentsList.length>0">
                        <article>
                            <header>
                                <address>
                                    <a href="javascript:void(0)">ഹാജരാക്കേണ്ട രേഖകൾ</a>
                                </address>
                            </header>
                            <div class="comcont">
                                <ol>
                                    <li class="plans-li" *ngFor="let planDetails of plan.documentsList">
                                        <span class="noselect" [innerHTML]="planDetails.details"
                                            [class.link-open]="planDetails.detailsLinkType>0"
                                            [class.cursor-pointer]="planDetails.detailsLinkType>0"
                                            (click)="planDetailLink(planDetails)">
                                        </span>
                                        <div *ngIf="planDetails.subDetails">
                                            <ul class="ul-listing">
                                                <li class="plans-li" *ngFor="let subDetail of planDetails.subDetails">
                                                    <span class="noselect" [innerHTML]="subDetail.details"
                                                        [class.link-open]="subDetail.detailsLinkType>0"
                                                        [class.cursor-pointer]="subDetail.detailsLinkType>0"
                                                        (click)="planDetailLink(subDetail)">
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                </ol>
                            </div>
                        </article>
                    </li>
                    <li *ngIf="plan.placeList&&plan.placeList.length>0">
                        <article>
                            <header>
                                <address>
                                    <a href="javascript:void(0)">അപേക്ഷ എവിടെ കൊടുക്കണം</a>
                                </address>

                            </header>
                            <div class="comcont">
                                <ol>
                                    <li class="plans-li" *ngFor="let planDetails of plan.placeList">
                                        <span class="noselect" [innerHTML]="planDetails.details"
                                            [class.link-open]="planDetails.detailsLinkType>0"
                                            [class.cursor-pointer]="planDetails.detailsLinkType>0"
                                            (click)="planDetailLink(planDetails)">
                                        </span>
                                        <div *ngIf="planDetails.subDetails">
                                            <ul class="ul-listing">
                                                <li class="plans-li" *ngFor="let subDetail of planDetails.subDetails">
                                                    <span class="noselect" [innerHTML]="subDetail.details"
                                                        [class.link-open]="subDetail.detailsLinkType>0"
                                                        [class.cursor-pointer]="subDetail.detailsLinkType>0"
                                                        (click)="planDetailLink(subDetail)">

                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                </ol>
                            </div>
                        </article>
                    </li>
                    <li *ngIf="plan.processList&&plan.processList.length>0">
                        <article>
                            <header>
                                <address>
                                    <a class="noselect" href="javascript:void(0)">നടപടിക്രമം</a>
                                </address>
                            </header>
                            <div class="comcont">
                                <ol>
                                    <li class="plans-li" *ngFor="let planDetails of plan.processList">
                                        <span class="noselect" [innerHTML]="planDetails.details"
                                            [class.link-open]="planDetails.detailsLinkType>0"
                                            [class.cursor-pointer]="planDetails.detailsLinkType>0"
                                            (click)="planDetailLink(planDetails)">
                                        </span>
                                        <div *ngIf="planDetails.subDetails">
                                            <ul class="ul-listing">
                                                <li class="plans-li" *ngFor="let subDetail of planDetails.subDetails">
                                                    <span class="noselect" [innerHTML]="subDetail.details"
                                                        [class.link-open]="subDetail.detailsLinkType>0"
                                                        [class.cursor-pointer]="subDetail.detailsLinkType>0"
                                                        (click)="planDetailLink(subDetail)">
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                </ol>
                            </div>
                        </article>
                    </li>
                    <li *ngIf="plan.linkList&&plan.linkList.length>0">
                        <article>
                            <header>
                                <address>
                                    <a class="noselect" href="javascript:void(0)">വിവരാത്മകമായ ലിങ്കുകൾ</a>
                                </address>
                            </header>
                            <div class="comcont">
                                <ol>
                                    <li class="plans-li" *ngFor="let planDetails of plan.linkList">
                                        <span class="noselect" [innerHTML]="planDetails.details"
                                            [class.link-open]="planDetails.detailsLinkType>0"
                                            [class.cursor-pointer]="planDetails.detailsLinkType>0"
                                            (click)="planDetailLink(planDetails)">

                                        </span>
                                        <div *ngIf="planDetails.subDetails">
                                            <ul class="ul-listing">
                                                <li class="plans-li" *ngFor="let subDetail of planDetails.subDetails">
                                                    <span class="noselect" [innerHTML]="subDetail.details"
                                                        [class.link-open]="subDetail.detailsLinkType>0"
                                                        [class.cursor-pointer]="subDetail.detailsLinkType>0"
                                                        (click)="planDetailLink(subDetail)">
                                                    </span>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                </ol>
                            </div>
                        </article>
                    </li>
                </ul>
            </div>
            <!-- ################################################################################################ -->
        </div>
        <!-- ################################################################################################ -->
        <div class="clear"></div>
    </main>
</div>

<app-footer></app-footer>