import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ComponentInteractionService {

  constructor() { }
  public hasPassedGuard: boolean = false;
  public userToken: String;
  public isShowLoading: boolean = false;
  public permissions: any;
  public userMap: any;
  public randomString = Math.floor((Math.random() * 10000) + 1);
}
