import { Component, OnInit, OnChanges } from "@angular/core";
import { Router, ActivatedRoute, NavigationExtras } from "@angular/router";
import { DepartmentService } from "../service/department.service";
import { Department } from "../model/department";
import { Plan } from "../model/plan";
import { HostListener } from "@angular/core";

@Component({
  selector: "app-department",
  templateUrl: "./department.component.html",
  styleUrls: ["./department.component.scss"],
})
export class DepartmentComponent implements OnInit {
  departmentDisplayId: string;
  department: Department = new Department(0, "", "", "", 0, "");
  planList: Plan[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private departmentService: DepartmentService
  ) {
    route.queryParams.subscribe((param) => {
      this.reloadCurrentRoute();
    });
  }
  @HostListener("contextmenu", ["$event"])
  onRightClick(event) {
    event.preventDefault();
  }

  ngOnInit(): void {
    window.scrollTo(0, 0);
    this.route.queryParams.forEach((params) => {
      this.departmentDisplayId = params["id_"];
      if (!this.departmentDisplayId || this.departmentDisplayId.length < 2) {
        this.router.navigate(["/home"]);
      } else {
        this.getDepartmentsByDisplayUrl();
        this.getPlansInDepartmentsByDisplayUrl();
      }
    });
  }
  reloadCurrentRoute() {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }

  getDepartmentsByDisplayUrl() {
    this.departmentService
      .getDepartmentsByDisplayUrl(this.departmentDisplayId)
      .then((response) => {
        if (response.status) {
          this.department = response.data;
        }
      });
  }
  getPlansInDepartmentsByDisplayUrl() {
    this.departmentService
      .getPlansInDepartmentsByDisplayUrl(this.departmentDisplayId)
      .then((response) => {
        if (response.status) {
          this.planList = [];
          response.data.forEach((element) => {
            if (element.description.length > 90)
              element.description =
                element.description.substring(0, 90) + "...";
            if (element.planName.length > 65)
              element.planName = element.planName.substring(0, 65) + "...";
            this.planList.push(element);
          });
        }
      });
  }

  navigatetoPlan(plan: Plan) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        id_: plan.displayUrlId,
      },
    };
    this.router.navigate(["/plan"], navigationExtras);
  }
}
