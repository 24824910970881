import { Component, OnInit } from '@angular/core';
import { DepartmentService } from '../service/department.service';
import { Constants } from '../constants/constants';
import { IfStmt } from '@angular/compiler';
import { NavigationExtras, Router } from '@angular/router';
import { Department } from '../model/department';

@Component({
  selector: 'app-title-section',
  templateUrl: './title-section.component.html',
  styleUrls: ['./title-section.component.scss']
})
export class TitleSectionComponent implements OnInit {

  schemeDepartmentList: Department[] = [];
  scholarshipDepartmentList: Department[] = [];
  ewsDepartmentList: Department[] = [];
  certificateDepartmentList: Department[] = [];
  edistrictDepartmentList: Department[] = [];
  notificationsDepartmentList: Department[] = [];

  isOpenMenu: boolean = false;
  constructor(private departmentService: DepartmentService, private router: Router) { }

  ngOnInit(): void {
    this.getDepartmentsByType(Constants.DEPARTMENT_TYPE_SHCEMES);
    this.getDepartmentsByType(Constants.DEPARTMENT_TYPE_SCHOLARSHIP);
    this.getDepartmentsByType(Constants.DEPARTMENT_TYPE_EWS);
    this.getDepartmentsByType(Constants.DEPARTMENT_TYPE_CERTIFICATES);
    this.getDepartmentsByType(Constants.DEPARTMENT_TYPE_E_DISTRICT);
    this.getDepartmentsByType(Constants.DEPARTMENT_TYPE_NOTIFICATIONS);
  }

  getDepartmentsByType(departmentTypeId: number) {
    this.departmentService.getDepartmentsByType(departmentTypeId).then(response => {
      if (response.status) {
        switch (departmentTypeId) {
          case Constants.DEPARTMENT_TYPE_SHCEMES:
            this.schemeDepartmentList = response.data;
            break;
          case Constants.DEPARTMENT_TYPE_SCHOLARSHIP:
            this.scholarshipDepartmentList = response.data;
            break;
          case Constants.DEPARTMENT_TYPE_EWS:
            this.ewsDepartmentList = response.data;
            break;
          case Constants.DEPARTMENT_TYPE_CERTIFICATES:
            this.certificateDepartmentList = response.data;
            break;
          case Constants.DEPARTMENT_TYPE_E_DISTRICT:
            this.edistrictDepartmentList = response.data;
            break;
          case Constants.DEPARTMENT_TYPE_NOTIFICATIONS:
            this.notificationsDepartmentList = response.data;
            break;
          default:
            break;
        }
      }
    });
  }

  navigatetoDepartment(department: Department) {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        "id_": department.displayUrlId
      }
    };
    this.router.navigate(['/department'], navigationExtras);
  }

  changeMenuVisibility() {
    this.isOpenMenu = !this.isOpenMenu;
  }
  navigateToPhotoGallery() {
    this.router.navigate(['/photo-album']);
  }
}
