<app-title-section></app-title-section>
<!-- ################################################################################################ -->
<!-- ################################################################################################ -->
<!-- ################################################################################################ -->
<div class="wrapper bgded overlay" style="background-image:url('./assets/images/afc_head.jpg');">
    <div id="breadcrumb" class="hoc clear">
        <!-- ################################################################################################ -->
        <h6 class="heading">Photo Gallery</h6>
        <ul>
            <li><a [routerLink]="['/home']">ഹോം</a></li>
            <li><a href="#">Photo Gallery</a></li>

        </ul>
        <!-- ################################################################################################ -->
    </div>
</div>
<!-- ################################################################################################ -->
<!-- ################################################################################################ -->
<!-- ################################################################################################ -->
<!-- ################################################################################################ -->
<!-- ################################################################################################ -->
<!-- ################################################################################################ -->

<div class="wrapper row3">
    <main class="hoc container clear">
        <!-- main body -->
        <!-- ################################################################################################ -->
        <div class="sectiontitle">
            <h6 class="heading">AFC Photo Gallery</h6>
            <!-- <p>Mauris tempor aenean pretium sem et luctus semper justo velit</p> -->
        </div>
        <ul class="nospace group overview">
            <li *ngFor="let album of albumList" class="one_third cursor-pointer album-image-container"
                (click)="navigatetoAlbumDetails(album)">
                <figure><a><img class="album-cover-image"
                            [src]="'/afc-restful/album/image?albumImageId='+album.albumCoverImageId" alt=""></a>
                    <figcaption>
                        <h6 class="heading">{{album.albumName}}</h6>
                        <p>{{album.albumDescription}}</p>
                    </figcaption>
                </figure>
            </li>
        </ul>
        <!-- ################################################################################################ -->
        <!-- / main body -->
        <div class="clear"></div>
    </main>
</div>


<!-- ################################################################################################ -->
<!-- ################################################################################################ -->
<!-- ################################################################################################ -->
<!-- ################################################################################################ -->
<!-- ################################################################################################ -->
<!-- ################################################################################################ -->
<app-footer></app-footer>